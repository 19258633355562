.status {
  line-height: 52px;
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 100%;
  height: 35px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 100%;
  border: 1px solid #aaa;
  background-color: #fff;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  max-height: 400px;
  overflow-y: auto;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #eeeeee;
}

.icon {
  position: absolute;
  top: 7px;
  width: 20px;
  height: 20px;
}

.search_btn {
  position: absolute;
  right: 0px;
  top: 10px;
  color: #db7020;
  font-size: 12px;
  text-align: left;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}

.inputContainer {
  position: relative;
}
